import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Row,
  Col,
  Container,
   TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { graphql } from "gatsby"
import Img from "gatsby-image"
import classnames from 'classnames';
import makeCarousel from 'react-reveal/makeCarousel';
// we'll need the Slide component for sliding animations
// but you can use any other effect
import Slide from 'react-reveal/Slide';






export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1'
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  render() {
    const data = this.props.data
    const webtasarim = data.webtasarim.childImageSharp.fluid
    const kurumsalmail = data.kurumsalmail.childImageSharp.fluid
    const amo = data.amo.childImageSharp.fluid
      const iosuygulama = data.iosuygulama.childImageSharp.fluid
        const haziryazilim = data.haziryazilim.childImageSharp.fluid
        //control
        const logocontrol = data.logocontrol.childImageSharp.fluid
        const control1 = data.control1.childImageSharp.fluid
        const control2 = data.control2.childImageSharp.fluid
        const control3 = data.control3.childImageSharp.fluid
        //Anadolu
        const logoanadolu = data.logoanadolu.childImageSharp.fluid
        const anadoluses1 = data.anadoluses1.childImageSharp.fluid
        const anadoluses2 = data.anadoluses2.childImageSharp.fluid
        const anadoluses3 = data.anadoluses3.childImageSharp.fluid
        //Effect
        const logoeffect = data.logoeffect.childImageSharp.fluid
        const effect1 = data.effect1.childImageSharp.fluid
        const effect2 = data.effect2.childImageSharp.fluid
        const effect3 = data.effect3.childImageSharp.fluid
        //gaye
        const logogaye = data.logogaye.childImageSharp.fluid
        const gaye1 = data.gaye1.childImageSharp.fluid
        const gaye2 = data.gaye2.childImageSharp.fluid
        const gaye3 = data.gaye3.childImageSharp.fluid

           const webcrm = data.webcrm.childImageSharp.fluid
       const slide1 = data.slide1.childImageSharp.fluid
        const slide2 = data.slide2.childImageSharp.fluid
         const slide3 = data.slide3.childImageSharp.fluid
         const testimonialbg = data.testimonialbg.childImageSharp.fluid
const iwebtheme = data.iwebtheme.childImageSharp.fluid


const CarouselUI = ({ children }) => <Container>{children}</Container>;
const Carousel = makeCarousel(CarouselUI);
    return (
			<Layout pageTitle="Anasayfa">
				<SEO title="Web Tasarım - E-Ticaret - Kurumsal Kimlik" keywords={[`istanbul Web Tasarım`, `Web Tasarım`, `E-Ticaret`]} description="İstanbul Web Tasarım,web tasarım, e-ticaret, kurumsal kimlik ve arama motoru optimizasyonu, sunucu kiralama hizmetlerini kaliteli ve en iyi olarak sunar" />


				    <section className="section slidealan bginpage pagebanner">
            <div className="zorgeliyor">
            <Carousel defaultWait={6000} /*wait for 1000 milliseconds*/ >
              <Slide right>
              <div className="relat">
              <Img fluid={slide1}
              style={{
                             position: 'absolute',
                             left: 0,
                             top: 0,
                             width: '100%',
                             height: '100%',
                             }} alt="Gaye Dekor Web Tasarım"/>
                             <Container>
                               <Row>
                                 <Col md="5">
                                 <div className="bginpagedark">
                                 <h1 className="mb20  section-title">
                                 Web Tasarım
                                      </h1>
                                      <p>Kendi özel tasrımlarımız ile sizlere opsiyonel özellikler barındıran görselliğin estetik ile buluştuğu web siteleri sunuyoruz...</p>
                               </div>  </Col>
                                 </Row>
                                 </Container>
              </div>
              </Slide>
              <Slide right>
                <div className="relat">
                <Img fluid={slide2}
                style={{
                               position: 'absolute',
                               left: 0,
                               top: 0,
                               width: '100%',
                               height: '100%',
                               }} alt="Gaye Dekor Web Tasarım"/>
                               <Container>
                                 <Row>
                                   <Col md="5">
                                   <div className="bginpagedark">
                                   <h1 className="mb20  section-title">
                                   E-Ticaret
                                        </h1>
                                        <p>İstanbul Web Tasarım ın geliştirdiği ve sizlere sunduğu e-ticaret siteleri ile sizde ürünlerinizi internet üzerinden pazarlayabilir ve kazancınıza kazanç katabilirsiniz!</p>
                                 </div>  </Col>
                                   </Row>
                                   </Container>

                </div>
              </Slide>
              <Slide right>
                <div className="relat">
                <Img fluid={slide3}
                style={{
                               position: 'absolute',
                               left: 0,
                               top: 0,
                               width: '100%',
                               height: '100%',
                               }} alt="Gaye Dekor Web Tasarım"/>
                               <Container>
                                 <Row>
                                   <Col md="5">
                                   <div className="bginpagedark">
                                   <h1 className="mb20  section-title">
                                   Arama Motoru Optimizasyonu
                                        </h1>
                                        <p>Anahtar sözcüklerinizde üst sıralarda çıkmak ve doğrudan doğruya potansiyel müşterilerinize ulaşmak için daha fazla beklemeyin...</p>
                                 </div>  </Col>
                                   </Row>
                                   </Container>

                </div>
              </Slide>
            </Carousel>
            </div>
				    </section>



			    <section className="services-area bg-fff  ptb-20 br-bot-de">
			        <Container>
			          <Row className="h-100 justify-content-center align-items-center">
			            <Col md="10" md-offset="2" className="text-center">
			            <h1 className="font-size-normal  section-title">
			                     <small>Web Tasarım &amp; E-Ticaret &amp; Arama Motoru Optimizasyonu &amp; Kurumsal e-Posta</small>
			                     İstanbul Web Tasarım
                            <div className="bar"></div>
			                 </h1>

			                                   <p>
			Burada Web Tasarım konuşulur, Firmanız için Web yazılımları yapılır, Kurumsal Kimliğiniz desteklenir, Firmanızı en iyi şekilde tanıtmak için fikirler üretilir. Bizim işimiz; bizi tercih edenleri potansiyel müşterilerine en iyi şekilde ulaştırmak ve tanıtmaktır,web tasarım, e-ticaret çözümü ,arama motoru optimizasyonu, kurumsal kimlik çalışması yaptırabileceğiniz işinize saygı gösteren ve işinize sizin titizliğinizde yaklaşan bir yer arıyorsanız, bütün birikimimizle, size yol göstermek ve sizinle birlikte ilerlemek için hazır ve istekliyiz. Siz de hazırsanız buyrun, başlayalım!</p>
			            </Col>
			            </Row>
			            </Container>
			</section>


			      <section>
						<div className="tabermanu">
			        <Container>
							<Nav tabs className="nav-justified">
			          <NavItem>
			            <NavLink
			              href="#" className={classnames({ active: this.state.activeTab === '1' })}
			              onClick={() => { this.toggle('1'); }}
			            >
			              Web Tasarım ve E-Ticaret
			            </NavLink>
			          </NavItem>
			          <NavItem>
			            <NavLink
			              className={classnames({ active: this.state.activeTab === '2' })}
			             href="#" onClick={() => { this.toggle('2'); }}
			            >
			              Arama Motoru Optimizasyonu
			            </NavLink>
			          </NavItem>

								<NavItem>
								<NavLink
									className={classnames({ active: this.state.activeTab === '3' })}
									href="#" onClick={() => { this.toggle('3'); }}
								>
			              Android - Ios Uygulama
			            </NavLink>
			          </NavItem>
								<NavItem>
								<NavLink
									className={classnames({ active: this.state.activeTab === '4' })}
									href="#" onClick={() => { this.toggle('4'); }}
								>
										Kurumsal E-Posta
									</NavLink>
								</NavItem>
								<NavItem>
								<NavLink
									className={classnames({ active: this.state.activeTab === '5' })}
									href="#" onClick={() => { this.toggle('5'); }}
								>
									 Hazır Tema ve Yazılım
									</NavLink>
								</NavItem>
			        </Nav>
							</Container>
							</div>
							<div className="taber">

								<TabContent activeTab={this.state.activeTab}>
								 <TabPane tabId="1" id="webtasarimtab">
								 <div className="webtasarimtab">
								 <Container>
								 <Row>


									 <Col lg="8" md="12" className="services-content">
									 <div className="boxer boxerpad opacitybox">
										 <div className="section-title">
										 <h3 className="font-size-normal">
																<small className="color-primary">Web Tasarım &amp; Web Yazılım</small>
																Web Tasarım , E-Ticaret
														</h3>
											 <p>Web tasarım çalışmalarımızın temelini; bizleri seçen müşterilerimizin ticari faaliyetlerini ve kurumsal kimliğini kalitelileştirmeye yönelik dinamik, estetik, kullanışlı, basit, internet kullanıcılarına dost, en iyi ve en son teknoloji ile donatılmış çalışmalar oluşturmaktadır.</p>
										 </div>

										 <Row>
											 <Col lg="4" md="6">
												 <div className="box">
														Web Tasarım ve Yazılım
												 </div>
											 </Col>

											 <Col lg="4" md="6">
												 <div className="box">
													 Responsive Tasarımlar
												 </div>
											 </Col>

											 <Col lg="4" md="6">
												 <div className="box">
														Yönetilebilir Kullanışlı Panel
												 </div>
											 </Col>

											 <Col lg="4" md="6">
												 <div className="box">
														Arama Motoru Optimizasyonu
												 </div>
											 </Col>

											 <Col lg="4" md="6">
												 <div className="box">
													Bütünlük ve Navigasyonlar
												 </div>
											 </Col>

											 <Col lg="4" md="6">
												 <div className="box">
														Rekabet Analizi ve Proje
												 </div>
											 </Col>

											 <Col lg="4" md="6">
												 <div className="box">
													Yedekleme ve Güncelleme
												 </div>
											 </Col>

											 <Col lg="4" md="6">
												 <div className="box">
												React,Vue,Nosql,Mysql,Php...
												 </div>
											 </Col>
											 <Col lg="4" md="6">
												 <div className="box">
												React,Vue,Nosql,Mysql,Php...
												 </div>
											 </Col>
										 </Row>
										 </div>
									 </Col>
									 <Col lg="4" md="12">


                  <Img fluid={webtasarim} className="boxer" />
									 <div className="greenbox boxer mb30 boxerpad">
									 <h4>Web Tasarım E-Ticaret Teklif İste</h4>
									 <p>Bir Web Tasarım ajansından çok daha fazlası ile yola koyulun, Potansiyel müşteriliniz ile tanışmaya davetlisiniz.</p>
                   <a href="/teklif-iste"
                     className="btn btn-outline-light mr-20"
                   >
                     Teklif İste
                   </a>
                   <a href="/web-tasarim"
                     className="btn btn-outline-light"
                   >
                     Ayrıntılı Bilgi
                   </a>
									 </div>
									 </Col>
								 </Row>
									 		</Container>
											</div>
								 </TabPane>
								 <TabPane tabId="2">
								 <Container>
									 <Row>
                   <Col lg="4" md="12">

  <Img fluid={amo} className="boxer" />
                   </Col>

										 <Col lg="8" md="12">
                      <div className="boxer boxerpad opacitybox">
											 <div className="section-titlse">
											 <h3 className="font-size-normal">
																<small className="color-success">Arama Motoru Optimizasyonu</small>
																Google Seo,Yandex Seo,Bing Seo ...
														</h3>
												 <p>İstanbul Web Tasarım değişen ve gelişen iş dünyasındaki üretkenliğinizi ve dinamizminizi artırmak için rekabet koşullarını sizin adınıza pozitif yönde şekillendirir. Anahtar sözcüklerinizde üst sıralarda çıkmak ve doğrudan doğruya potansiyel müşterilerinize ulaşmanızı hedefler.Yerli ve yabancı sektörlerde yeni güçlü imajlar ve markalar meydana getirmek ve kalıcı üstünlükler sağlamayı temel görevimiz olarak biliyoruz.</p>
											 </div>

                       </div>
										 </Col>

									 </Row>
                   <Row>
                    <Col md="12">
                    <div className="redbox boxer mb30 boxerpad">
                    <h4>Web Tasarım E-Ticaret Teklif İste</h4>
                    <p>Arama Motoru Optimizasyonu çalışmamız ile internette sıralamaları alt üst edin! Potansiyel müşterileriniz tarafından bulunabilir olmak için hazır olun !</p>
                    <a href="/teklif-iste"
                      className="btn btn-outline-light mr-20"
                    >
                      Teklif İste
                    </a>
                    <a href="/arama-motoru-optimizasyonu"
                      className="btn btn-outline-light"
                    >
                      Ayrıntılı Bilgi
                    </a>
                    </div>
                    </Col>
                   </Row>
								 </Container>
								 </TabPane>

								  <TabPane tabId="3">
                  <Container>
                    <Row>


                      <Col lg="8" md="12">
                       <div className="boxer boxerpad opacitybox">
                        <div className="section-titlse">
                        <h3 className="font-size-normal">
                                   <small className="color-red">Android & IOS Uygulama Geliştirme</small>
                                   Mobil uygulama Geliştirme
                               </h3>
                          <p>Bir fikriniz mi var ? Günlük hayatımızda potansiyel müşterilinize en hızlı ve en etkileşimli şekilde kalabileceğiniz Android ve IOS uygulamalarımız ile tanışmaya davetlisiniz.Fikirleriniz bizler için değerlidir geleceğin teknolojileri kullanarak sizlere maximum etkileşim imkanı sunan android ve IOS uygulama geliştirmeleri konusunda yardımcı olabiliriz.</p>
                        </div>

                        </div>
                      </Col>
                      <Col lg="4" md="12">
                        <Img fluid={iosuygulama} className="boxer" />



                      </Col>
                    </Row>
                    <Row>
                     <Col md="12">
                     <div className="bluebox boxer mb30 boxerpad">
                     <h4>Web Tasarım E-Ticaret Teklif İste</h4>
                     <p>Alan Adınızı hemen Kaydederek adınıza tahsis edilen alan adı barındırma hizmeti ile kurumsal ihaleler ve çok daha fazlası için kurumsal kimliğinizi mail adresleriniz ile yansıtın !</p>
                  <a href="/teklif-iste"
                       className="btn btn-outline-light mr-20"
                     >
                       Teklif İste
                     </a>

                     </div>
                     </Col>
                    </Row>
                  </Container>

									</TabPane>
                  <TabPane tabId="4">
                  <Container>
                    <Row>
                    <Col lg="4" md="12">
  <Img fluid={kurumsalmail} className="boxer" />
                    </Col>

                      <Col lg="8" md="12">
                       <div className="boxer boxerpad opacitybox">
                        <div className="section-titlse">
                        <h3 className="font-size-normal">
                                   <small className="color-red">Firma Maili &amp; Şirket Maili</small>
                                   Kurumsal e-Posta Hizmeti
                               </h3>
                          <p>Kurumsal kimlik firmanızın dış dünyayla etkileşim şekli olarak nitelendirebiliriz.Interaktif dunyada kurumsal kimliğinizin potansiyel müşteriler,tedarikciler vb kişi ve kuruluşlar tarafından güven ve kalite izleniminin uyandırması gerektiğinin farkındasınız.Öyleyse ilk ve önemli adımlardan biri olan kesintisiz ve kaliteli mail sunucularında @firmadi.com( net-org-com.tr ) şeklindeki yeni mailleriniz ile çalışmaya hazır olun.</p>
                        </div>

                        </div>
                      </Col>

                    </Row>
                    <Row>
                     <Col md="12">
                     <div className="warningbox boxer mb30 boxerpad">
                     <h4>Kurumsal e-Posta Hizmetiyle Başlayın !</h4>
                     <p>Alan Adınızı hemen Kaydederek adınıza tahsis edilen alan adı barındırma hizmeti ile kurumsal ihaleler ve çok daha fazlası için kurumsal kimliğinizi mail adresleriniz ile yansıtın !</p>
                    <a href="/teklif-iste"
                       className="btn btn-outline-light mr-20"
                     >
                       Teklif İste
                     </a>

                     </div>
                     </Col>
                    </Row>
                  </Container>
                  </TabPane>

										<TabPane tabId="5">
                    <Container>
                      <Row>


                        <Col lg="8" md="12">
                         <div className="boxer boxerpad opacitybox">
                          <div className="section-titlse">
                          <h3 className="font-size-normal">
                                     <small className="color-red">Wordpress Tema & Magento Tema & Opencart Tema vb.</small>
                                     Hazır Tema ve Yazılım
                                 </h3>
                            <p>İstanbul Web Tasarım olarak 10 yılı aşkın deneyimimizi çok daha fazla kişiyi potansiyel müşterilerine en ekonomik şekilde ulaşabilmesi hedefi ile Açık kaynak kodlu sistemler için geliştirdiğimiz temaları market yerlerinde kullanıma hazır şekilde satışa sunduk.Ajans kalitesinde web yazılımı sahibi olmak için themeren.Com da 5 yıldızlı yazılımcı olan IwebTheme kullanıcısını inceleyebilirsiniz.</p>
                          </div>

                          </div>
                        </Col>
                        <Col lg="4" md="12">

<Img fluid={haziryazilim} className="boxer" />
                        </Col>
                      </Row>
                      <Row>
                       <Col md="12">
                       <div className="lowebox boxer mb30 boxerpad">
                       <h4>Themeren ile Wordpress Teması</h4>
                       <p>Themeren üzerinden IwebTheme kullanıcısı olarak porfoy oluşturduk.Market yerinden wordpress,magento,opencart vb açık kaynak kodlu yazılımlar için hazırlandığımız temalarımızı uygun fiyata alarak kullanabilirsiniz.</p>
                       <a href="/"
                         className="btn btn-outline-light mr-20"
                       >
                         Themeren ( IwebTheme )
                       </a>
                       <a href="/"
                         className="btn btn-outline-light"
                       >
                         Ayrıntılı Bilgi
                       </a>
                       </div>
                       </Col>
                      </Row>
                    </Container>

									</TabPane>
							 </TabContent>

							</div>

			      </section>


<section className="section funfact bg-parallax mob-no-bg relat">
 <Img fluid={webcrm}
 style={{
                position: 'absolute',
                left: 0,
                top: 0,
                width: '100%',
                height: '100%',
              }} alt="Web Tasarım Kodladık"/>
  <Container>
    <Row>
      <Col lg={{ size: 8, offset: 4 }} md="12">
      <div className="boxer boxerpad text-center">
      <h1 className="font-size-normal section-title">
          <small>Web Tasarım Ajansı</small>
          Web Tasarım için En İyisiyiz !
      </h1>

<Row>
            <Col md="4" sm="6" xs="6">
                <div className="fact mb-100">
    <div className="facticon"><i className="fa fa-sitemap" aria-hidden="true"></i></div>

                    <div className="fact-number timer">
                        <span className="factor">745</span>
                    </div>
                    <span className="fact-title">Web Tasarım Projesi</span>
                </div>
           </Col>

              <Col md="4" sm="6" xs="6">
                <div className="fact">
    <div className="facticon"><i className="fa fa-shopping-bag" aria-hidden="true"></i></div>

                    <div className="fact-number timer">
                        <span className="factor">56</span>
                    </div>
                    <span className="fact-title">E-Ticaret Projesi</span>
                </div>
            </Col>

             <Col md="4" sm="12" xs="12">
                <div className="fact">
    <div className="facticon"><i className="fa fa-code" aria-hidden="true"></i></div>

                    <div className="fact-number timer">
                        <span className="factor">40milyon+</span>
                    </div>
                    <span className="fact-title">Satır Kod</span>
                </div>
            </Col>


</Row>
<Row>
<Col sm="12">
        <h4 className="pt25">Bir Fikriniz mi Var ? </h4>
        <p className="pb10 alpha8">Yüzlerce kişi bizlerden web tasarım hizmeti alarak firmasına değerine değer kattı,Ürünlerini ve firmalarını internet kullanıcılarına en doğru şekilde ulaştırdı,Yüzbinlerce ziyaretici sitelerini ziyaret etti,defalarca başarı öyküsü yazıldı,müşterilerimizden yüzlerce teşekkür aldık ve siz de hazırsanız mutlaka ne yapabileceğimize ve neler yaptığımıza göz atın !</p>
        <a href="/" className="btn btn-danger">Neler Yapabiliriz ?</a>

    </Col>
</Row>


      </div>
      </Col>
    </Row>
  </Container>
</section>

			        <section id="servicefact" className="bg-grad-stellar pt100 pb100">
					<div className="dunya">
			            <Container>
			                <Row className="row no-gutters">
			                  <Col sm="12" md="12"  className="mb50">
                        <h1 className="font-size-normal section-title color-light">
                            <small>İşletmeniz için Web Tasarım ve Yazılımdan Çok Daha Fazlası</small>
                      Profesyonel Yazılım - Web Tasarım
                        </h1>
                              <p className="pb10 colorb9 text-center">İstanbul Web Tasarım olarak sizlerle çalışmak için her zaman hazır ve istekliyiz.Bulut tabanlı yazılımlarınız için tek ve en doğru adrestisiniz!Oldukça iddalıyız, işinizi bir üst aşamaya taşımak için buradayız !</p>

			                    </Col>
			                </Row>

			                <Row className="row no-gutters">
                      <Col lg="3" md="6" sm="6" xs="12">
                         <div className="servicefact">
                         <h4>Web Tasarım ve Yazılım Hizmetleri</h4>
                         <p>İstanbul Web Tasarım sizlere bulut tabanlı kaliteli web yazılımları ve çok daha fazlasını hazırlar.</p>
                         <div className="serviceicon"><i className="fa fa-3x fa-code" aria-hidden="true"></i></div>
                         <a href="/web-tasarim">Web Tasarım</a>
                         </div>
                     </Col>

                                 <Col lg="3" md="6" sm="6" xs="12">
                                   <div className="servicefact">
                                    <h4>E-Ticaret ve Entegre Sistemler</h4>
                                    <p>E-Ticaret ile bugünden satış yapmaya başlayın! En işlek caddede bir bayiniz olsun.</p>
                                   <div className="serviceicon"><i className="fa fa-3x fa-code" aria-hidden="true"></i></div>
                                    <a href="/e-ticaret">E-Ticaret</a>
                                   </div>
                               </Col>

			                             <Col lg="3" md="6" sm="6" xs="12">
			                                <div className="servicefact">
                                      <h4>Arama Motoru Optimizasyonu</h4>
                                      <p>İstanbul Web Tasarım Olarak kalıcı üstünlükler sağlayarak müşterilerinize ulaşmanız da etkin rol oynuyoruz.</p>
            													<div className="serviceicon"><i className="fa fa-3x fa-code" aria-hidden="true"></i></div>
                                      <a href="/arama-motoru-optimizasyonu">Arama Motoru Optimizasyonu</a>
			                                </div>
			                            </Col>

                                  <Col lg="3" md="6" sm="6" xs="12">
                                     <div className="servicefact">
                                     <h4>Hazır Yazılım ve CMS Temaları</h4>
                                     <p>Ajans kalitesi ile web tasarım ! Açık kaynak kodlu sistemler için hazır tema ve eklentilerimiz ile tanışmaya davetlisiniz.</p>
                                     <div className="serviceicon"><i className="fa fa-3x fa-code" aria-hidden="true"></i></div>
                                     <a href="/">İncele</a>
                                     </div>
                                 </Col>
			                </Row>


			            </Container>
						</div>
			        </section>


			        <section  className="section referanslar bg-parallax br-bot-de mob-no-bg">
			          <Container>
			            <Row>
			            <Col sm="12" md={{ size: 8, offset: 2 }}>
			            <h1 className="font-size-normal text-center"><small>Aramıza Yeni Katılan Web Tasarım , E-Ticaret , Kurumsal Kimlik Projeleri</small> Web Tasarım Referans Projeler</h1>
                  <p className="text-center">İstanbul Web Tasarım olarak yalnızca İstanbul odaklı değiliz ! Türkiyenin dörtbir yanında kurumsal firmalara, kurumsal kaliteli web tasarım, e-ticaret,arama motoru optimizasyonu projelerimiz ile buluşturuyoruz.</p>
			  </Col>
			            </Row>

			            <div className="opacityblock">
			            <Row>
			              <Col md="5">
			                <div className="numberheighlight"><Img fluid={logocontrol} />
			                </div>
			                <h4>Control Teknoloji ve Sanayi</h4>
			                <p className="alpha-color">Control Teknoloji ve Sanayi A.Ş için hazırladığımız Otomasyon sistemi ile entegre özel dinamik yazılım.Dinamik kodlama yapısı ,temiz html5 ve css3 kalıbı ile yazıldı.</p>
                      <p>Adres : www.Control.Com.Tr<br />
                    Firma Lokasyonu : Mersin</p>
			              </Col>
			              <Col md="7">
			                <Row>

			                  <Col lg="4" md="12" sm="12" xs="12">
			                  <div className="refimg">
			                    <figure>
			                     <Img fluid={control1} alt="Control Teknoloji Web Tasarım"/>
			                    </figure>
			                  </div>
			                  </Col>
			                  <Col lg="4" md="6" sm="6" xs="6">
			                  <div className="refimg">
			                    <figure>
			                     <Img fluid={control2} alt="Control Teknoloji Web Tasarım"/>
			                    </figure>
			                  </div>
			                  </Col>
			                  <Col lg="4" md="6" sm="6" xs="6">
			                  <div className="refimg">
			                    <figure>
			                    <Img fluid={control3} alt="Control Teknoloji Web Tasarım" />
			                    </figure>
			                  </div>
			                  </Col>
			                </Row>
			              </Col>
			              </Row>
			  </div>
			      <div className="opacityblock">
			            <Row>
			            <Col md="5">
			                <div className="numberheighlight"><Img fluid={logoanadolu}  alt="Anadolu Müzik Ses" />
			                </div>
			                <h4>Anadolu Ses , Işık ve Görüntü Sistemleri</h4>
			                <p className="alpha-color">Anadolu Ses Işık ve Görüntü Sistemleri için hazırladığımız Web Yazılım ve Tasarım hizmetimiz açık kaynak kodlu sistem üzerine dinamik olarak kodlanmıştır.Html5 Css3 yapısı ile kurumsal kimliği temiz ve şık bir site vasıtası ile potansiyel müşterilere iletilmiştir.</p>
			             <p>Adres : www.anadoluses.com<br />
                   Firma Lokasyonu : Ankara</p>
                    </Col>
			              <Col md="7">
			                <Row>
			                  <Col lg="4" sm="12" xs="12">

			                  <div className="refimg">
			                    <figure>
			                     <Img fluid={anadoluses1} alt="Anadolu Müzik Ses Web Tasarım" />
			                    </figure>
			                  </div>
			                  </Col>
                        <Col lg="4" md="6" sm="6" xs="6">

                       <div className="refimg">
                         <figure>
                        <Img fluid={anadoluses2} alt="Anadolu Müzik Ses Web Tasarım"/>
                         </figure>
                       </div>
                       </Col>
			                  <Col lg="4" md="6" sm="6" xs="6">
			                  <div className="refimg">
			                    <figure>
			                     <Img fluid={anadoluses3} alt="Anadolu Müzik Ses Web Tasarım" />
			                    </figure>
			                  </div>
			                  </Col>

			                </Row>
			              </Col>
			              </Row>
			              </div>
			                <div className="opacityblock">
			            <Row>
			              <Col md="5">
			                <div className="numberheighlight"> <Img fluid={logogaye}  alt="Gaye Dekor Logo" />
			                </div>
			                <h4>Gaye Dekor</h4>
			                <p className="alpha-color">Ankara Çay Yolu Dekorasyon Gaye Dekor için hazırladığımız Web Yazılım ve Tasarım hizmetimiz açık kaynak kodlu sistem üzerine dinamik olarak kodlanmıştır.Html5 Css3 yapısı ile kurumsal kimliği temiz ve şık bir site vasıtası ile potansiyel müşterilere iletilmiştir.Referans projeleri için galeri vb özellikler bulunmaktadır.</p>
			             <p>Adres : www.gayedekor.com <br />
                   Firma Lokasyonu : Ankara</p> </Col>
			              <Col md="7">
			                <Row>

			                  <Col lg="4" sm="12" xs="12">
			                  <div className="refimg">
			                    <figure>
			                      <Img fluid={gaye1} alt="Gaye Dekor Web Tasarım"/>
			                    </figure>
			                  </div>
			                  </Col>
			                  <Col lg="4" md="6" sm="6" xs="6">
			                  <div className="refimg">
			                    <figure>
			                     <Img fluid={gaye2} alt="Gaye Dekor Web Tasarım"/>
			                    </figure>
			                  </div>
			                  </Col>
			                  <Col lg="4" md="6" sm="6" xs="6">
			                  <div className="refimg">
			                    <figure>
			                    <Img fluid={gaye3} alt="Gaye Dekor Web Tasarım"/>
			                    </figure>
			                  </div>
			                  </Col>
			                </Row>
			              </Col>
			                </Row>
			  </div>


        <div className="opacityblock">
    <Row>
      <Col md="5">
        <div className="numberheighlight"><Img fluid={logoeffect} alt="Effect Aluminyum Logo"/>
        </div>
        <h4>Effect Aluminyum</h4>
        <p className="alpha-color">Effect Aluminyum için hazırladığımız Web Yazılım ve Tasarım hizmetimiz açık kaynak kodlu sistem üzerine dinamik olarak kodlanmıştır.Html5 Css3 yapısı ile kurumsal kimliği temiz ve şık bir site vasıtası ile potansiyel müşterilere iletilmiştir.</p>
  <p>Adres : www.effectaluminyum.com<br />
  Firma Lokasyonu : Ankara</p>    </Col>
      <Col md="7">
        <Row>

          <Col lg="4" sm="12" xs="12">
          <div className="refimg">
            <figure>
            <Img fluid={effect1}  alt="Effect Aluminyum Web Tasarım"/>
            </figure>
          </div>
          </Col>
          <Col lg="4" md="6" sm="6" xs="6">
          <div className="refimg">
            <figure>
          <Img fluid={effect2} alt="Effect Aluminyum Web Tasarım"/>
            </figure>
          </div>
          </Col>
          <Col lg="4" md="6" sm="6" xs="6">
          <div className="refimg">
            <figure>
          <Img fluid={effect3} alt="Effect Aluminyum Web Tasarım"/>
            </figure>
          </div>
          </Col>
        </Row>
      </Col>
        </Row>
 </div>
        <Row>
			  <Col sm="12" md={{ size: 8, offset: 2 }} className="text-center mt-30">
        <a href="/referanslar"
          className="btn btn-outline-danger"
        >
          Diğer Referans Web Tasarım ve Yazılım Projeleri
        </a>
			</Col>
			  </Row>
			          </Container>
			        </section>


<section className="section bg-f5 br-bot-de repeat-bg testimonials">
<Img fluid={testimonialbg}
 style={{
                position: 'absolute',
                left: 0,
                top: 0,
                width: '100%',
                height: '100%',
              }} alt="Web Tasarım Müsterileri"/>
<Container>
<Row>
<Col sm="12" md={{ size: 8, offset: 2 }} className="text-center">
<h1 className="font-size-normal section-title color-light">
    <small>Bizi Tercih Edenler Ne Söylediler ?</small>
  Yazılım ve Web Tasarım Müşteri Yorumları
<div className="bar"></div>
</h1>
</Col>
  </Row>

  <Row>
    <Col sm="12" md={{ size: 8, offset: 2 }} className="text-center feedback">
    <div className="testimonialspadd">
    <Carousel defaultWait={6000} /*wait for 1000 milliseconds*/ >
      <Slide right>
      <div className="testimonialcaraousel">
      <div className="testimonialcaraouselbody">
      <div className="testimonialcaraouselimage">
      <Img fluid={iwebtheme} alt="Effect Aluminyum Logo"/>
      </div>
      <h5>
      Sunucu Barındırma Web Tasarım
           </h5>
           <p className="titlework">Mehmet Alp</p>
  <p>Başarılı ve Kaliteli çalışmalarını Control.Com.TR nın sunucu barındırma hizmeti ile taçlandıran İstanbul Web Tasarım renkli ve oldukça keyifli web siteleri sunmaya devam ettiğini görmek bizleri oldukça mutlu ediyor</p>
    </div>

      </div>
      </Slide>
      <Slide right>
      <div className="testimonialcaraousel">
      <div className="testimonialcaraouselbody">
      <div className="testimonialcaraouselimage">
      <Img fluid={iwebtheme} alt="Effect Aluminyum Logo"/>
      </div>
      <h5>
      Endüstriyal Soğutma Web Tasarım
           </h5>
           <p className="titlework">Engin Ercan</p>
    <p>Endustriyel soğutma firmamız için hazırlattığımız web sitemizin üst sıralarda yer alması ve potansiyel müşterilerimize kendimizi en iyi şekilde tanıtabildiğimiz için İstanbul Web Tasarım a Teşekkürler</p>
    </div>

      </div>
      </Slide>
      <Slide right>
      <div className="testimonialcaraousel">
      <div className="testimonialcaraouselbody">
      <div className="testimonialcaraouselimage">
      <Img fluid={iwebtheme} alt="Effect Aluminyum Logo"/>
      </div>
      <h5>
      Estetik Kliniği Web Tasarım
           </h5>
           <p className="titlework">Tuna Karataş</p>
    <p>Yaptırmış olduğum Arama motoru Optimizasyonu çalışmalarının başarısızlığı sektöre olan güvenimi yıkmıştı fakat bu noktada İstanbul Web Tasarım Farklı, farkları ise sonuca ulaşmış olmalarıdır.</p>
    </div>

      </div>
      </Slide>
    </Carousel>
    </div>
    </Col>

  </Row>
</Container>
</section>

		<section className="section calltoactionsec ">
    <Container>
    <Row>
    <Col sm="12" md={{ size: 8, offset: 2 }} className="text-center">
    <h1 className="font-size-normal">
        <small>Bulut Tabanlı Yazılım Geliştirme</small>
        Web Tasarım için En İyisiyiz !

    </h1>
    <p>İstanbul Web Tasarım olarak sizleri kaliteli,kurumsal,işinde profesyonel ekip tarafından geliştirilen bulut tabanlı yazılımlarımız ile tanışmaya davet ediyoruz.Bünyemizde Web Tasarım,Web Yazılım Geliştirme,E-Ticaret,E-Ticaret Entegrasyon Sistemleri,Android ve IOS Native Uygulama geliştirme,Arama Motoru Optimizasyonu,Hastane Yönetim Bilişim Sistemleri,Klinik Yazılımları,CRM,Muhasebe Yazılımları,Alan adı barındırma ve alan adı kaydı gibi bir çok hizmeti bulundurmaktayız.</p>

    <a href="/teklif-iste"
      className="btn btn-outline-danger mr-20"
    >
      Web Tasarım Teklif İste
    </a>
    <a href="/iletisim"
      className="btn btn-outline-primary"
    >
    İletişim
    </a>
    </Col>
      </Row>
    </Container>
    </section>
			  </Layout>
    );
  }
}

export const pageQuery = graphql`
query {
   webtasarim: file(
    relativePath: { regex: "/web-tasarim-metro.jpg/" }
  ) {
childImageSharp {
              fluid{
                ...GatsbyImageSharpFluid_withWebp
              }
            }
  }
  kurumsalmail: file(
   relativePath: { regex: "/kurumsal-mail.jpg/" }
 ) {
childImageSharp {
            fluid{
               ...GatsbyImageSharpFluid_withWebp
             }
           }
 }
 amo: file(
  relativePath: { regex: "/arama-motoru-opt.jpg/" }
) {
childImageSharp {
          fluid{
              ...GatsbyImageSharpFluid_withWebp
            }
          }
}
iosuygulama: file(
 relativePath: { regex: "/ios-uygulama.jpg/" }
) {
childImageSharp {
        fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
haziryazilim: file(
 relativePath: { regex: "/hazir-yazilim.jpg/" }
) {
childImageSharp {
        fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
logocontrol: file(
 relativePath: { regex: "/ss/logocontrol.png/" }
) {
childImageSharp {
           fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
control1: file(
 relativePath: { regex: "/ss/controlss1.jpg/" }
) {
childImageSharp {
           fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
control2: file(
 relativePath: { regex: "/ss/controlss2.jpg/" }
) {
childImageSharp {
           fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
control3: file(
 relativePath: { regex: "/ss/controlss3.jpg/" }
) {
childImageSharp {
           fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
logoanadolu: file(
 relativePath: { regex: "/ss/logoanadolu.png/" }
) {
childImageSharp {
          fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
anadoluses1: file(
 relativePath: { regex: "/ss/anadoluss1.jpg/" }
) {
childImageSharp {
        fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
anadoluses2: file(
 relativePath: { regex: "/ss/anadoluss2.jpg/" }
) {
childImageSharp {
        fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
anadoluses3: file(
 relativePath: { regex: "/ss/anadoluss3.jpg/" }
) {
childImageSharp {
          fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
logoeffect: file(
 relativePath: { regex: "/ss/logoeffect.png/" }
) {
childImageSharp {
          fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
effect1: file(
 relativePath: { regex: "/ss/effectss1.jpg/" }
) {
childImageSharp {
           fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
effect2: file(
 relativePath: { regex: "/ss/effectss2.jpg/" }
) {
childImageSharp {
           fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
effect3: file(
 relativePath: { regex: "/ss/effectss3.jpg/" }
) {
childImageSharp {
          fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
logogaye: file(
 relativePath: { regex: "/ss/logogaye.png/" }
) {
childImageSharp {
           fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
gaye1: file(
 relativePath: { regex: "/ss/gayess1.jpg/" }
) {
childImageSharp {
          fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
gaye2: file(
 relativePath: { regex: "/ss/gayess2.jpg/" }
) {
childImageSharp {
           fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
gaye3: file(
 relativePath: { regex: "/ss/gayess3.jpg/" }
) {
childImageSharp {
           fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
webcrm: file(
 relativePath: { regex: "/webbg.jpg/" }
) {
childImageSharp {
           fluid(quality: 95){
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
slide1: file(
 relativePath: { regex: "/slide1.jpg/" }
) {
childImageSharp {
           fluid(quality: 100){
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
slide2: file(
 relativePath: { regex: "/slide7.jpg/" }
) {
childImageSharp {
           fluid(quality: 100){
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
slide3: file(
 relativePath: { regex: "/slide5.jpg/" }
) {
childImageSharp {
           fluid(quality: 100){
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
testimonialbg: file(
 relativePath: { regex: "/testimonialbg.jpg/" }
) {
childImageSharp {
           fluid(quality: 95){
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
iwebtheme: file(
 relativePath: { regex: "/kklogo.png/" }
) {
childImageSharp {
           fluid(quality: 95){
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
}
`
